import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SectionTitle } from "../components/section-title";
import { BoxNameArrow } from "../components/box-name-arrow";
import { useSelector, useDispatch } from "react-redux";
import { Footer } from "../components/footer/Footer";
import { actions } from "./redux/ScannerRedux";
import { toasterActions } from "../_redux/ToasterRedux";
export const EventsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const scanner = useSelector((state) => state.scanner.scanner);
  const events = useSelector((state) => state.scanner.events);
  const selectedEvent = useSelector((state) => state.scanner.selectedEvent);

  useEffect(() => {
    const _body = {
      page: 1,
      limit: 1000,
      filters: {
        isClosed: false,
      },
    };
    dispatch(actions.events.search(_body));
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.selectedEvent.reset());
    dispatch(actions.entryPoint.reset());
  }, [dispatch]);

  useEffect(() => {
    if (events && events.error) {
      dispatch(
        toasterActions.toaster.set({
          message: events.error,
          variant: "danger",
          show: true,
        })
      );
    }
  }, [dispatch, events, history, selectedEvent]);

  const checkScannerPath = useCallback(
    (event) => {
      let path = "";
      if (scanner && scanner.data && scanner.data.type === "badge-collection") {
        path = "/badge-collection-scanner";
      } else {
        if (scanner?.data.type === "counter") path = "/counter";
        else {
          if (event.isRfid) path = "/rfid";
          else path = "/qrscan";
        }
      }
      return path;
    },
    [scanner]
  );

  const handleSelectedEvent = useCallback(
    (event) => {
      if (scanner && scanner.data) {
        dispatch(actions.selectedEvent.set(event));
        const isRFID = event.isRfid;
        dispatch(actions.isRFID.set(isRFID));
        const path = checkScannerPath(event);
        dispatch(actions.scannerPath.set(path));
        setTimeout(() => {
          switch (scanner.data.type) {
            case "badge-collection":
              history.push("/welcome");
              break;

            case "validator":
              history.push(path);
              break;

            case "qa":
              history.push(path);
              break;

            case "workshop":
              history.push("/workshops");
              break;

            case "counter":
              history.push("/counter");
              break;

            default:
              history.push("/points");
              break;
          }
        }, 500);
      }
    },
    [checkScannerPath, dispatch, history, scanner]
  );

  return (
    <>
      <div className="row mx-0 padding position-absolute top-50 start-50 translate-middle w-100">
        <SectionTitle name="Select Event" />
        <div
          className="col-12 px-0 row mx-0 mt-5 justify-content-center"
          style={{
            maxHeight: "400px",
            overflow: "auto",
          }}
        >
          {events &&
            events.data &&
            events.data.data &&
            events.data.data.length > 0 &&
            events.data.data.map((event, index) => (
              <BoxNameArrow
                marginLeft={false}
                key={index}
                name={event.name}
                code={event.code}
                onClick={() => handleSelectedEvent(event)}
              />
            ))}
        </div>
      </div>

      {scanner && scanner.data && (
        <Footer
          path={`${scanner.data.name}`}
          onClick={() => {
            setTimeout(() => {
              dispatch(actions.scanner.resetScanner());
              history.push("/scanner");
            }, 500);
          }}
        />
      )}
    </>
  );
};
