import clsx from "clsx";
import React, { useMemo } from "react";
import { useBreakPoint } from "../../../../hook/useBreakPoint";
import classes from "./result.module.css";
export const InfoResult = ({
  scanResult: {
    code,
    message,
    barcode,
    visitorName,
    visitorPhoto,
    category,
    title,
    position,
    organization,
    workshop,
    productName,
    seatRow,
    seatNo,
    locationSlug,
  },
}) => {
  const { down } = useBreakPoint();
  const isMobile = useMemo(() => {
    return down("xs");
  }, [down]);

  return (
    <div className="px-0 mx-0">
      {message && (
        <div className="px-0 mt-3">
          <p className="text-danger">{message}</p>
        </div>
      )}

      {/* TICKET ID */}
      {barcode && (
        <div className="px-0 mt-3">
          <span className="text-light fs-6">{barcode}</span>
        </div>
      )}

      {code && (
        <div className="px-0 mt-3">
          <span className="text-light fs-6">{code}</span>
        </div>
      )}

      {/* NAME - POSTISION - ORGANIZATION */}

      {visitorName && (
        <div
          className={clsx(
            "display-flex-row d-flex flex-row flex-wrap-content justify-content-between w-100 px-0 mt-2",
            classes.infoContainer
          )}
        >
          <div className="d-flex flex-row">
            {visitorPhoto && (
              <div className="w-25 me-3">
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.src = "./assets/images/defaultImage.png";

                    currentTarget.onerror = null;
                  }}
                  width="100%"
                  src={
                    visitorPhoto
                      ? visitorPhoto
                      : "./assets/images/defaultImage.png"
                  }
                  alt="visitorPhoto"
                />
              </div>
            )}

            <div className="w-100">
              <span className="badge bg-info text-light">{category}</span>
              <p
                className={`text-light ${
                  isMobile ? "fs-4" : "fs-1"
                } text-uppercase w-auto`}
              >
                {title && <span>{title + " "}</span>}
                {visitorName}
              </p>

              {position && (
                <p className={`text-white ${!isMobile && "fs-5"}`}>
                  {position}
                </p>
              )}
              {organization && (
                <p className={`text-white ${!isMobile && "fs-5"}`}>
                  {organization}
                </p>
              )}

              {workshop && (
                <p className={`text-white ${!isMobile && "fs-5"}`}>
                  {workshop.name}
                </p>
              )}

              {seatRow && seatNo && locationSlug && (
                <p
                  className={`text-white text-uppercase ${!isMobile && "fs-5"}`}
                >
                  {`Section ${locationSlug} ${seatRow}${seatNo}`}
                </p>
              )}

              {productName && (
                <p
                  className={`badge bg-secondary text-white mt-2 fs-6 ${
                    !isMobile && "fs-5"
                  }`}
                >
                  {productName}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
