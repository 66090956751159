import PropTypes from "prop-types";
import clsx from "clsx";
import { useBreakPoint } from "../../hook/useBreakPoint";
import { useMemo } from "react";
export const Footer = ({ path, onClick }) => {
  const { down } = useBreakPoint();

  const isMobile = useMemo(() => {
    return down("xs");
  }, [down]);
  return (
    <p
      className={clsx(
        "position-fixed bottom-0 start-0 m-3 text-white text-uppercase",
        {
          "fs-6": !isMobile,
          tinyText: isMobile,
        }
      )}
      style={{
        width: "55%",
      }}
      onClick={() => onClick ** onClick(true)}
    >
      {path}
    </p>
  );
};

Footer.propTypes = {
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Footer.defaultProps = {
  path: null,
  onClick: () => {},
};
