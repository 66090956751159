import { actions } from "../../redux/ScannerDetailsRedux";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toasterActions } from "../../../_redux/ToasterRedux";
import { ScanTicket } from "../../redux/ScannerCRUD";

export const useDispatchRfidQrResult = () => {
  const dispatch = useDispatch();
  const scanner = useSelector((state) => state.scanner.scanner);
  const isQA = useMemo(() => {
    if (scanner && scanner.data && scanner.data.type === "qa") return true;
    return false;
  }, [scanner]);

  const handleDispatchWithCustomerData = useCallback(
    ({
      code,
      checkIn,
      checkOut,
      startedAt,
      endedAt,
      name,
      title,
      photo,
      category,
      customerOrganization,
      nationality,
      type,
      debounceCode,
      uhfid,
      ticketLocations,
      designation,
      product,
      workshop,
      message,
      seatRow,
      seatNo,
      barcode,
      locationSlug,
      outcome,
    }) => {
      let fullName = "";
      let visitorPhoto = "";
      fullName = title ? title + " " : "" + name ? name : "";
      visitorPhoto =
        photo && photo.slug
          ? process.env.REACT_APP_STATIC_URL + "/" + photo.slug
          : null;

      dispatch(
        actions.setResult({
          code,
          is_rfid: type === "rfid" ? true : false,
          ticketCode: type === "rfid" ? uhfid : debounceCode.trim(),
          outcome: outcome,
          visitorName: fullName,
          message: message,
          visitorPhoto: visitorPhoto,
          nationality: nationality,
          gateLocations: ticketLocations || "",
          checkIn: checkIn || false,
          checkOut: checkOut || false,
          startedAt: startedAt ? moment(startedAt).format("YYYY/MM/DD") : "",
          endedAt: endedAt ? moment(endedAt).format("YYYY/MM/DD") : "",
          productName: product && product.name,
          workshop,
          organization: customerOrganization,
          position: designation,
          category,
          seatRow,
          seatNo,
          barcode,
          locationSlug,
        })
      );
    },
    [dispatch]
  );

  const handleDispatchInvalid = useCallback(
    ({ type, message, ticketCode, outcome }) => {
      dispatch(
        actions.setResult({
          is_rfid: type === "rfid" ? true : false,
          outcome: outcome ? outcome : "Invalid Ticket",
          message: message ? message : "Invalid Ticket",
          ticketCode,
        })
      );
    },
    [dispatch]
  );

  const handleError = useCallback(
    (error) => {
      dispatch(
        toasterActions.toaster.set({
          variant: "danger",
          message: "Error",
        })
      );
    },
    [dispatch]
  );

  const getData = useCallback(
    async ({
      type,
      dataBody,
      debounceCode,
      setIsSubmitting,
      setResult,
      entryPoint,
      history,
      goto,
    }) => {
      ScanTicket(dataBody)
        .then((res) => {
          if (scanner?.data.type === "counter") {
            handleDispatchWithCustomerData({
              outcome: "Thank you!",
              code: debounceCode,
              type: "rfid",
            });
            history.replace(goto);
            return;
          }
          // 200
          const {
            code,
            checkIn,
            checkOut,
            startedAt,
            endedAt,
            ticketLocations,
            uhfid,
            product,
            workshop,
            customer,
            seatRow,
            seatNo,
            barcode,
            location,
          } = res.data || {};

          const { slug } = location || {};

          const {
            name,
            title,
            photo,
            category,
            customerOrganization,
            nationality,
            designation,
          } = customer || {};

          // THERE IS CUSTOMER
          if (customer) {
            handleDispatchWithCustomerData({
              code,
              checkIn,
              checkOut,
              startedAt,
              endedAt,
              name,
              title,
              photo,
              category,
              customerOrganization,
              nationality,
              type,
              entryPoint,
              debounceCode,
              uhfid,
              ticketLocations,
              designation,
              product,
              seatRow,
              seatNo,
              barcode,
              workshop,
              locationSlug: slug,
              outcome: !entryPoint
                ? isQA
                  ? "Access Granted"
                  : "Thank you!"
                : entryPoint.name === "Entry Point"
                ? "Access Granted"
                : "Thank you!",
            });
          }
          // THERE IS NO CUSTOMER
          else {
            handleDispatchInvalid({
              type,
              ticketCode: debounceCode.trim(),
            });
          }
          setIsSubmitting(false);
          setResult("");
          history.replace(goto);
        })
        .catch((error) => {
          if (error?.response?.status) {
            switch (error.response.status) {
              // 400
              case 400:
                if (
                  error.response.data.error === "Invalid Ticket" ||
                  error.response.data.error === "Data is invalid"
                ) {
                  dispatch(
                    actions.setResult({
                      is_rfid: type === "rfid" ? true : false,
                      outcome: "Invalid Ticket",
                      message: error.response.data?.error || "Error",
                      ticketCode: debounceCode.trim(),
                    })
                  );
                  history.replace(goto);
                } else {
                  const { message, ticketLocations, ticket } =
                    error.response.data.data || {};
                  const {
                    code,
                    endedAt,
                    startedAt,
                    checkIn,
                    checkOut,
                    product,
                    workshop,
                    customer,
                    seatRow,
                    seatNo,
                    barcode,
                    location,
                  } = ticket || {};

                  const { slug } = location || {};

                  const {
                    name,
                    title,
                    photo,
                    category,
                    customerOrganization,
                    nationality,
                    designation,
                  } = customer || {};

                  // THERE IS CUSTOMER
                  if (customer) {
                    handleDispatchWithCustomerData({
                      code,
                      checkIn,
                      checkOut,
                      startedAt,
                      endedAt,
                      name,
                      title,
                      photo,
                      category,
                      customerOrganization,
                      nationality,
                      type,
                      entryPoint,
                      debounceCode,
                      ticketLocations,
                      designation,
                      product,
                      workshop,
                      message,
                      seatRow,
                      seatNo,
                      barcode,
                      locationSlug: slug,
                      outcome: "Access Denied",
                    });
                  }
                  // THERE IS NO CUSTOMER
                  else {
                    handleDispatchInvalid({
                      type,
                      ticketCode: debounceCode.trim(),
                    });
                  }
                  setIsSubmitting(false);
                  setResult("");
                  history.replace(goto);
                }

                break;

              case 500:
                if (error.response.data.error) {
                  setIsSubmitting(false);
                  setResult("");
                  handleError(error.response.data.error);
                } else {
                  const { outcome, message } =
                    error.response &&
                    error.response.data &&
                    error.response.data.data &&
                    error.response.data.data;
                  handleDispatchInvalid({
                    type: false,
                    outcome: outcome ? outcome : "",
                    message: message ? message : "",
                    ticketCode: debounceCode.trim(),
                  });
                  setIsSubmitting(false);
                  setResult("");
                  history.replace(goto);
                }
                break;

              default:
                handleDispatchInvalid({
                  type: false,
                  ticketCode: debounceCode.trim(),
                });
                setIsSubmitting(false);
                setResult("");
                handleError("Error");
                history.replace(goto);
                break;
            }
          } else {
            setIsSubmitting(false);
            setResult("");
            handleError("Error");
          }
        });
    },
    [
      dispatch,
      handleDispatchInvalid,
      handleDispatchWithCustomerData,
      handleError,
      isQA,
      scanner?.data.type,
    ]
  );

  return useMemo(
    () => ({
      getData,
    }),
    [getData]
  );
};
