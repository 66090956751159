import { SectionTitle } from "../components/section-title";
import { useFormik } from "formik";
import { InputField } from "../components/input-field";
import { useEffect, useMemo } from "react";
import * as yup from "yup";
import { CustomButton } from "../components/custom-button/CustomButton";
import { actions } from "./redux/ScannerRedux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useBreakPoint } from "../hook/useBreakPoint";
import { toasterActions } from "../_redux/ToasterRedux";
const validations = yup.object().shape({
  scannerCode: yup.string().required("Code is required"),
});

export const ScannerPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const scanner = useSelector((state) => state.scanner.scanner);
  const { down } = useBreakPoint();

  const isMobile = useMemo(() => {
    return down("xs");
  }, [down]);

  const initialValues = useMemo(() => {
    return {
      scannerCode: "",
    };
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validations,
    onSubmit: async (values) => {
      dispatch(actions.scanner.search({ code: values.scannerCode }));
    },
  });

  useEffect(() => {
    dispatch(actions.scanner.resetScanner());
  }, [dispatch]);

  useEffect(() => {
    if (scanner && scanner.data && scanner.data.code) history.push("/events");
    if (scanner && scanner.error) {
      dispatch(
        toasterActions.toaster.set({
          message: "Invalid Code",
          variant: "danger",
        })
      );
      dispatch(actions.scanner.resetScanner());
    }
  }, [dispatch, formik, history, scanner]);

  const isSubmitting = useMemo(() => {
    return scanner && scanner.isLoading !== undefined && scanner.isLoading;
  }, [scanner]);

  return (
    <div className="position-absolute top-50 start-50 translate-middle ">
      <div className="d-flex flex-column">
        <SectionTitle name="Enter the Scanner Code" />

        <div
          className={clsx("px-0 mx-0 w-100 text-center", {
            "mt-5": !isMobile,
            "mt-4": isMobile,
          })}
          style={{
            maxHeight: "60vh",
            overflowY: "auto",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className="w-100 my-3">
              <InputField
                name="scannerCode"
                id="scannerCode"
                placeholder="Code"
                type="text"
                value={formik.values.scannerCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.scannerCode}
              />
            </div>
            <div className="mt-5">
              <CustomButton
                isSubmitting={isSubmitting}
                disabled={isSubmitting || !formik.isValid}
                label="Submit"
                title="Submit"
                type="submit"
              >
                Next
              </CustomButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
