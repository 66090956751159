import React, { useEffect, useContext, useMemo, Fragment } from "react";
import { ResultHeader } from "./result-header";
import { InfoResult } from "./info-result";
import { BackButton } from "../../../components/back-button/BackButton";
import authContext from "../../../context/auth-context";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { useBreakPoint } from "../../../hook/useBreakPoint";

const TIMEOUT = 5000;
export const CheckinScannerResult = () => {
  const history = useHistory();
  const show = useContext(authContext);
  const scanResult = useSelector((state) => state.scannerDetails.scanResult);
  const scannerPath = useSelector((state) => state.scanner.scannerPath);
  const scanner = useSelector((state) => state.scanner.scanner);
  const selectedEvent = useSelector((state) => state.scanner.selectedEvent);
  const theEntryPoint = useSelector((state) => state.scanner.entryPoint);
  const { down } = useBreakPoint();

  const isMobile = useMemo(() => {
    return down("xs");
  }, [down]);

  useEffect(() => {
    document.getElementById("mainBox").style.overflowX = "hidden";
  }, [history]);

  const handleOnIdle = () => {
    history.replace(scannerPath);
  };

  useIdleTimer({
    timeout: TIMEOUT,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    if (!selectedEvent) {
      history.replace("/events");
    }
  }, [history, scanner, selectedEvent, theEntryPoint]);

  useEffect(() => {
    show.setLogoutCtxt(true);
    show.setBackButtonCtxt(true);
  }, [show]);

  const result = useMemo(() => {
    switch (scanResult.outcome) {
      case "Access Granted":
        return {
          show: true,
          icon: true,
          background: "success",
        };

      case "Valid":
        return {
          show: true,
          icon: true,
          background: "success",
        };

      case "Thank you!":
        return {
          show: true,
          icon: true,
          background: "info",
        };

      case "Access Denied":
        return {
          show: true,
          icon: false,
          background: "danger",
        };

      case "Invalid":
        return {
          show: true,
          icon: false,
          background: "danger",
        };

      case "Invalid Ticket":
        return {
          show: false,
          icon: false,
          background: "danger",
        };

      default:
        history.replace(scannerPath);
        break;
    }
  }, [history, scanResult.outcome, scannerPath]);

  return (
    <>
      {result && result.show ? (
        <div className="mx-0">
          <ResultHeader result={result} scanResult={scanResult} />
          <div className={`px-5 ${isMobile ? "mx-1" : "mx-5"}`}>
            <InfoResult scanResult={scanResult} />
          </div>

          <div
            className={`px-5 py-3 overflow-auto ${
              isMobile ? "mx-1" : "mx-5"
            } mb-5 mt-2 d-flex flex-row flex-wrap gap-2`}
            style={{
              maxHeight: "35vh",
            }}
          >
            {scanResult.gateLocations && scanResult.gateLocations.length > 0 && (
              <div className="mt-2">
                <p className={`fs-4 text-light mt-5`}>Locations:</p>
                <div className="d-flex flex-wrap gap-2 mt-2">
                  {scanResult.gateLocations.map((item, idx) => {
                    return (
                      <Fragment key={item.slug + idx}>
                        <CustomButton
                          type="button"
                          title={item.name}
                          classes="fs-6 mt-0"
                          disabled={true}
                        />
                        {item.children &&
                          item.children.length > 0 &&
                          item.children.map((child) => {
                            return (
                              <CustomButton
                                key={child.slug + idx}
                                type="button"
                                title={child.name}
                                classes="fs-6 mt-0"
                                disabled={true}
                              />
                            );
                          })}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : result && !result.show ? (
        <div
          className="position-absolute top-50 start-50 translate-middle w-100"
          style={{ display: result && result.show ? "none" : "block" }}
        >
          <p className="fs-4 text-light p-4">{scanResult.ticketCode}</p>

          <div className={`w-100 py-5 bg-${result.background}`}>
            <p className="text-light fs-1 ps-5 ms-5">Invalid Ticket</p>
          </div>

          <div className="mt-3">
            <p className="p-4 text-danger">
              <small>{scanResult.message}</small>
            </p>
          </div>
        </div>
      ) : null}
      <BackButton
        handleClick={() => {
          history.replace(scannerPath);
        }}
      />
    </>
  );
};
