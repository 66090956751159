import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Footer } from "../../components/footer/Footer";
import { gsap } from "gsap";
import { useSocketIO } from "../../hook/useSocketIO";
import { actions } from "../redux/ScannerRedux";
import { PinModal } from "../../components/pin-modal/PinModal";
export const WelcomePage = () => {
  const history = useHistory();
  const [showCode, setShowCode] = useState(false);
  const scanner = useSelector((state) => state.scanner.scanner);
  const selectedEvent = useSelector((state) => state.scanner.selectedEvent);
  const dispatch = useDispatch();

  const socketName = useMemo(() => {
    if (selectedEvent && scanner && scanner.data) {
      return `${selectedEvent.code}:${scanner.data.code}:DEVICE`;
    }
    return null;
  }, [selectedEvent, scanner]);

  const socketCallback = useCallback(
    (data) => {
      if (data.qrCode) history.push("/badge-collection-scanner");
    },
    [history]
  );

  useSocketIO({ socketCallback, socketName });

  useEffect(() => {
    var boxes = document.getElementsByClassName("letter");
    var boxesArr = Array.from(boxes);

    boxesArr.forEach(function (box) {
      gsap.fromTo(
        ".letter",
        { y: "-20%" },
        {
          y: "45%",
          stagger: 0.5,
          repeat: -1,
          yoyo: true,
          scrollTrigger: {
            trigger: box,
            scrub: true,
            start: "top bottom",
            end: "top -60%",
            immediateRender: false,
          },
        }
      );
    });
  }, []);

  return (
    <div className="h-100 w-100">
      <div className="position-absolute top-50 start-50 translate-middle d-flex">
        <div
          className="me-3 text-info"
          style={{
            fontSize: "30px",
          }}
        >
          <div>A</div>
          <div>D</div>
          <div>M</div>
          <div>M</div>
        </div>
        <div
          className="d-flex flex-column justify-content-center"
          style={{
            height: "175px",
            borderLeft: "6px solid white",
            overflow: "hidden",
          }}
        >
          <div
            className="d-flex text-primary"
            style={{
              fontSize: "4em",
            }}
          >
            <div className="letter">W</div>
            <div className="letter">E</div>
            <div className="letter">L</div>
            <div className="letter">C</div>
            <div className="letter">O</div>
            <div className="letter">M</div>
            <div className="letter">E</div>
          </div>
        </div>
      </div>

      {scanner && scanner.data && selectedEvent && (
        <Footer
          path={`${scanner.data.name} / ${selectedEvent.name}`}
          onClick={() => {
            setTimeout(() => {
              setShowCode(true);
            }, 500);
          }}
        />
      )}

      <PinModal
        show={showCode}
        handleClose={() => setShowCode(false)}
        onSubmit={() => {
          setTimeout(() => {
            dispatch(actions.selectedEvent.reset());
            history.push("/events");
          }, 500);
        }}
      />
    </div>
  );
};
