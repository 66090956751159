import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import authContext from "../context/auth-context";
import { SectionTitle } from "../components/section-title";
import { BoxNameArrow } from "../components/box-name-arrow";
import { useSelector, useDispatch } from "react-redux";
import { Footer } from "../components/footer/Footer";
import { actions } from "./redux/ScannerRedux";
export const PointsPage = () => {
  const show = useContext(authContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const scanner = useSelector((state) => state.scanner.scanner);
  const selectedEvent = useSelector((state) => state.scanner.selectedEvent);
  const selectedWorkshop = useSelector(
    (state) => state.scanner.selectedWorkshop
  );
  const scannerPath = useSelector((state) => {
    return state.scanner.scannerPath;
  });
  useEffect(() => {
    show.setLogoutCtxt(true);
    show.setBackButtonCtxt(false);
  }, [show]);

  useEffect(() => {
    if (
      scanner &&
      scanner.data &&
      (scanner.data.type === "validator" || scanner.data.type === "qa")
    )
      history.replace("/events");
  }, [history, scanner]);

  useEffect(() => {
    dispatch(actions.entryPoint.reset());
  }, [dispatch]);

  const goTo = (link) => {
    const timer = setTimeout(() => {
      history.push(link);
      clearTimeout(timer);
    }, 200);
  };

  return (
    <>
      <div className="position-absolute top-50 start-50 translate-middle w-75">
        <SectionTitle name="Select Point" />
        <div className="px-0 row mx-0 mt-5 justify-content-center">
          <BoxNameArrow
            marginLeft={true}
            key={0}
            name="Entry Point"
            image="enter"
            onClick={() => {
              dispatch(
                actions.entryPoint.set({
                  name: "Entry Point",
                })
              );
              const confirm = setTimeout(() => {
                history.replace(scannerPath);
                clearTimeout(confirm);
              }, 200);
            }}
          />
          <BoxNameArrow
            marginLeft={true}
            name="Exit Point"
            image="exit"
            onClick={() => {
              dispatch(
                actions.entryPoint.set({
                  name: "Exit Point",
                })
              );
              goTo(scannerPath);
            }}
          />
          {/* <BuildPoint key={0} name="Entry Point" image={true} clicked={goToQRCode}/>
                    <BuildPoint key={1} name="Exit Point" image={false} clicked={goToQRCode} /> */}
        </div>
      </div>

      {scanner && scanner.data && selectedEvent && (
        <Footer
          path={`${scanner.data.name} / ${selectedEvent.name}${
            selectedWorkshop ? " / " + selectedWorkshop.name : null
          }`}
          onClick={() => {
            goTo(selectedWorkshop ? "/workshops" : "/events");
          }}
        />
      )}
    </>
  );
};
