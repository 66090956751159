import React, { useState, useEffect } from "react";
import Toolbar from "../navigation/tooltbar/toolbar";
import AuthContext from "../../context/auth-context";
import NetworkStatus from "../../NetWorkStatus";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ToasterModal } from "../toaster-modal/ToasterModal";
const Layout = ({ children }) => {
  const [showLogout, setLogOut] = useState(false);
  const [showBackBtn, setBackButton] = useState(false);
  const [networkstatus, setNetworkStatus] = useState(true);
  const [userName, setUserName] = useState(localStorage.getItem("userName"));
  const { pathname } = useLocation();

  const history = useHistory();
  const scanner = useSelector((state) => state.scanner.scanner);

  useEffect(() => {
    if (!scanner || !scanner.data) history.replace("/scanner");
  }, [history, scanner]);

  return (
    <AuthContext.Provider
      value={{
        showLogout: showLogout,
        setLogoutCtxt: setLogOut,
        showBackButton: showBackBtn,
        setBackButtonCtxt: setBackButton,
        netWorkStatus: networkstatus,
        setNetWorkStatusCtxt: setNetworkStatus,
        user: userName,
        setuserCtxt: setUserName,
      }}
    >
      {(scanner?.data?.type !== "counter" || !pathname.includes("counter")) && (
        <Toolbar />
      )}
      <main id="mainBox" className="overflow-hidden">
        {children}
      </main>
      <ToasterModal />
      <NetworkStatus />
    </AuthContext.Provider>
  );
};

export default Layout;
