import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  toaster: {
    set: "[SCANNER] SELECTED TOASTER SET",
    reset: "[SCANNER] SELECTED TOASTER RESET",
  },
};

const initialState = {
  toaster: undefined,
};
export const reducer = persistReducer(
  {
    storage,
    key: "admm-toaster",
    whitelist: ["toaster"],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.toaster.set: {
        const toaster = action.payload;
        return {
          ...state,
          toaster: {
            ...toaster,
            show: true,
          },
        };
      }

      case actionTypes.toaster.reset: {
        return { ...state, toaster: undefined };
      }
      default:
        return state;
    }
  }
);

export const toasterActions = {
  toaster: {
    set: (data) => ({
      type: actionTypes.toaster.set,
      payload: data,
    }),
    reset: () => ({ type: actionTypes.toaster.reset }),
  },
};
