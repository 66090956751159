import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { useHistory } from "react-router-dom";
import { useDispatchRfidQrResult } from "./hook/useDispatchRfidQrResult";
import { PinModal } from "../../components/pin-modal/PinModal";
import { useIdleTimer } from "react-idle-timer";
import authContext from "../../context/auth-context";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../redux/ScannerRedux";
import { QRCodeReader } from "../../components/qr-code/QRCodeReader";
import { Footer } from "../../components/footer/Footer";
import { toasterActions } from "../../_redux/ToasterRedux";
const timeout = 10000;
export const ScannerValidatorQRCodePage = () => {
  const show = useContext(authContext);
  const dispatch = useDispatch();
  const scanner = useSelector((state) => state.scanner.scanner);
  const selectedEvent = useSelector((state) => state.scanner.selectedEvent);
  const selectedWorkshop = useSelector(
    (state) => state.scanner.selectedWorkshop
  );
  const isRFID = useSelector((state) => state.scanner.isRFID);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const entryPoint = useSelector((state) => state.scanner.entryPoint);
  const { getData } = useDispatchRfidQrResult();
  const [showPinCode, setShowPinCode] = useState(false);

  const handleOnIdle = () => {
    history.replace("/try-again");
  };

  useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  const history = useHistory();
  const [result, setResult] = useState("");

  useEffect(() => {
    show.setLogoutCtxt(true);
    show.setBackButtonCtxt(false);
  }, [show]);

  useEffect(() => {
    if (
      !entryPoint &&
      scanner &&
      scanner.data &&
      scanner.data.type !== "validator" &&
      scanner.data.type !== "qa"
    ) {
      history.replace("/points");
    }
  }, [history, scanner, entryPoint]);

  const checkResult = useCallback(
    async (debounceCode) => {
      if (
        debounceCode &&
        !isSubmitting &&
        scanner &&
        scanner.data &&
        selectedEvent
      ) {
        setIsSubmitting(true);
        const _data = {
          ticketCode: debounceCode.trim(),
          gateCode: scanner.data.code.trim(),
          workshopCode: selectedWorkshop ? selectedWorkshop.code : undefined,
          isCheckedIn: !entryPoint
            ? true
            : entryPoint.name === "Entry Point"
            ? true
            : false,
          eventCode: selectedEvent.code.trim(),
        };

        getData({
          type: "qrCode",
          dataBody: _data,
          debounceCode,
          setIsSubmitting,
          setResult: () => setResult(""),
          entryPoint,
          history: history,
          goto: "/scanner-result",
        });
      }
    },
    [
      isSubmitting,
      scanner,
      selectedEvent,
      selectedWorkshop,
      entryPoint,
      getData,
      history,
    ]
  );

  const handleScan = useCallback(
    async (data) => {
      if (data) {
        let qrcode;
        try {
          if (data.includes("?q=")) {
            const url = new URL(data);
            qrcode = new URLSearchParams(url.search).get("q");
          } else {
            qrcode = data;
          }
          if (qrcode) {
            setResult(qrcode);
            checkResult(qrcode);
          }
        } catch (err) {
          dispatch(
            toasterActions.toaster.set({
              variant: "danger",
              message: "Error",
            })
          );
        }
      }
    },
    [checkResult, dispatch]
  );

  const footerTitle = useMemo(() => {
    if (scanner?.data && selectedEvent && entryPoint && selectedWorkshop)
      return `${scanner.data.name} / ${selectedEvent.name}/ ${selectedWorkshop.name} / ${entryPoint.name}`;
    if (scanner?.data && selectedEvent && entryPoint)
      return `${scanner.data.name} / ${selectedEvent.name} / ${entryPoint.name}`;
    if (scanner?.data && selectedEvent && !entryPoint)
      return `${scanner.data.name} / ${selectedEvent.name}`;
  }, [scanner.data, selectedEvent, entryPoint, selectedWorkshop]);

  const handlePinSubmit = useCallback(() => {
    setTimeout(() => {
      if (
        scanner &&
        scanner.data &&
        (scanner.data.type === "validator" || scanner.data.type === "qa")
      ) {
        history.push("/events");
      } else {
        history.push("/points");
      }
    }, 500);
  }, [history, scanner]);
  return (
    <>
      {!isSubmitting && (
        <QRCodeReader
          isLoading={isSubmitting}
          handleScan={handleScan}
          result={result}
        />
      )}
      <Footer
        path={footerTitle}
        onClick={() => {
          setTimeout(() => {
            setShowPinCode(true);
          }, 500);
        }}
      />

      <PinModal
        show={showPinCode}
        handleClose={() => setShowPinCode(false)}
        onSubmit={handlePinSubmit}
      />

      {isRFID && (
        <div
          className="position-fixed bottom-0 end-0 m-3 scan-with-qrcode-or-rfid"
          onClick={() => {
            dispatch(actions.scannerPath.set("/rfid"));
            setTimeout(() => {
              history.push("/rfid");
            }, 500);
          }}
        >
          Scan with RFID
        </div>
      )}
    </>
  );
};
