import { useContext, useEffect } from "react";
import authContext from "../../context/auth-context";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { BackButton } from "../../components/back-button/BackButton";
import { CounterHeader } from "./counter-header";
const TIMEOUT = 5000;
export const CounterResultPage = () => {
  const scanResult = useSelector((state) => state.scannerDetails.scanResult);
  const scannerPath = useSelector((state) => state.scanner.scannerPath);
  const show = useContext(authContext);
  const history = useHistory();
  useEffect(() => {
    show.setBackButtonCtxt(true);
  }, [show]);

  useEffect(() => {
    document.getElementById("mainBox").style.height = "100vh";
  }, [history]);

  const handleOnIdle = () => {
    history.replace(scannerPath);
  };

  useIdleTimer({
    timeout: TIMEOUT,
    onIdle: handleOnIdle,
  });

  return (
    <>
      <div className="position-absolute top-50 start-50 translate-middle w-100">
        <CounterHeader
          result={{
            icon: true,
            backgrounf: "",
          }}
          scanResult={scanResult}
        />
      </div>

      <BackButton
        handleClick={() => {
          history.replace(scannerPath);
        }}
      />
    </>
  );
};
