import { useHistory } from "react-router-dom";
import { Footer } from "../../components/footer/Footer";
import { actions } from "../redux/ScannerRedux";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useCallback, useEffect } from "react";
import { useSocketIO } from "../../hook/useSocketIO";
import { useBreakPoint } from "../../hook/useBreakPoint";
import { StopBadgeCollection } from "../redux/ScannerCRUD";
import { toasterActions } from "../../_redux/ToasterRedux";
import clsx from "clsx";
const DETAILS_TO_SHOW = [
  // { key: "code", name: "code", icon: "bi-key" },
  { key: "customerOrganization", name: "organization", icon: "bi-building" },
  // { key: "procucts", name: "Category", icon: "bi-globe" },
  // { key: "designation", name: "designation", icon: "bi-person-workspace" },
  // { key: "email", name: "email", icon: "bi-envelope" },
  // { key: "mobile", name: "mobile", icon: "bi-phone" },
];

export const CustomerDetailsPage = () => {
  const customer = useSelector((state) => state.scanner.customer);
  const scanner = useSelector((state) => state.scanner.scanner);
  const selectedEvent = useSelector((state) => state.scanner.selectedEvent);
  const history = useHistory();
  const dispatch = useDispatch();
  const { down } = useBreakPoint();
  const isMobile = useMemo(() => {
    return down("xs");
  }, [down]);

  const isiPad = useMemo(() => {
    return down("md");
  }, [down]);

  const stopBadge = useCallback(async () => {
    if (scanner && scanner.data && selectedEvent) {
      try {
        const dataBody = {
          payload: {
            gateCode: scanner.data.code,
          },
          eventCode: selectedEvent.code,
        };
        await StopBadgeCollection(dataBody);
      } catch (err) {
        dispatch(
          toasterActions.toaster.set({
            message: err.response,
            variant: "danger",
          })
        );
      }
    }
  }, [dispatch, scanner, selectedEvent]);

  useEffect(() => {
    if (!customer.data) {
      stopBadge();
      history.replace("/welcome");
    }
  }, [customer, history, stopBadge]);

  const socketCallback = useCallback(
    (data) => {
      if (data && data.qrCode === false) {
        dispatch(actions.customer.resetCustomer());
        history.push("/welcome");
      }
      if (data && data.code && scanner && scanner.data) {
        const dataBody = {
          payload: {
            customerCode: data.code,
            gateCode: scanner.data.code,
          },
          eventCode: selectedEvent.code,
        };
        dispatch(actions.customer.search(dataBody));
      }
    },
    [dispatch, history, scanner, selectedEvent]
  );

  const socketName = useMemo(() => {
    if (selectedEvent && scanner && scanner.data) {
      return `${selectedEvent.code}:${scanner.data.code}:DEVICE`;
    }
    return null;
  }, [selectedEvent, scanner]);

  useSocketIO({ socketCallback, socketName });

  const selectedDetails = useMemo(() => {
    const array = [];
    if (customer && customer.data) {
      Object.keys(customer.data).forEach((key) => {
        const found = DETAILS_TO_SHOW.find((item) => item.key === key);
        if (found) {
          array.push(
            <div
              key={key}
              className="mt-3 d-flex flex-row gap-2 text-secondary"
              style={{
                textTransform: "capitalize",
              }}
            >
              <p className="me-2">
                {found.icon && (
                  <i className={`me-3 bi text-primary ${found.icon}`}></i>
                )}
                {found.name}:
              </p>
              <p className="d-flex flex-column justify-content-center">
                {customer.data[key].toLowerCase()}
              </p>
            </div>
          );
        }
      });
      return array;
    }
    return null;
  }, [customer]);

  const products = useMemo(() => {
    return customer?.data?.products || [];
  }, [customer?.data?.products]);

  const image = useMemo(() => {
    if (customer && customer.data && customer.data.photo) {
      return (
        <img
          className="rounded-circle border border-primary"
          width={isMobile ? "100px" : "170px"}
          height={isMobile ? "100px" : "170px"}
          alt={customer.data.photo.name}
          src={`${process.env.REACT_APP_STATIC_URL}/${customer.data.photo.slug}`}
        />
      );
    } else return null;
  }, [customer, isMobile]);

  const customerName = useMemo(() => {
    if (customer && customer.data) {
      const name = `${
        customer.data.salutation ? customer.data.salutation + " " : ""
      } ${customer.data.name ? customer.data.name : ""}`;

      return (
        <p
          className="mb-0 text-uppercase fs-sm-3 text-primary"
          style={{
            fontSize: isMobile ? "2rem" : "6.5vw",
            fontWeight: "bolder",
          }}
        >
          {name}
        </p>
      );
    }
    return null;
  }, [customer, isMobile]);

  return (
    <div
      className="p-3"
      style={{
        fontFamily: "sans-serif",
      }}
    >
      {customer && customer.data && (
        <div
          className={clsx(
            "rounded d-flex flex-column p-3",
            isMobile ? "gap-5" : isiPad ? "gap-5" : "gap-2"
          )}
        >
          <div className="d-flex flex-row gap-3">
            <div
              style={{
                minWidth: isMobile ? "100px" : "170px",
              }}
            >
              {image}
            </div>
            <div className="d-flex flex-column justify-content-center">
              {customerName}
            </div>
          </div>
          <div>
            <div
              className="d-flex flex-column mt-3"
              style={{
                fontSize: isMobile ? "17px" : "30px",
              }}
            >
              {selectedDetails}
              <div
                className="mt-3 d-flex flex-row gap-2 text-secondary"
                style={{
                  textTransform: "capitalize",
                }}
              >
                <p className="me-2">
                  <i className={`me-3 bi text-primary bi-person-workspace`}></i>
                  Category:
                </p>

                {products &&
                  products.length &&
                  products.map((item, idx, array) => {
                    const isLast = idx === array.length - 1;
                    return (
                      <p className="d-flex flex-column justify-content-center">
                        {`${item.slug.toLowerCase()} ${!isLast ? ", " : ""}`}
                      </p>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}

      {scanner && scanner.data && selectedEvent && (
        <Footer
          path={`${scanner.data.name} / ${selectedEvent.name} / welcome`}
          // onClick={() => {
          //   setTimeout(() => {
          //     dispatch(actions.customer.resetCustomer());
          //     history.push("/welcome");
          //   }, 500);
          // }}
        />
      )}
    </div>
  );
};
