import React, { useEffect, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import authContext from "../context/auth-context";
import { SectionTitle } from "../components/section-title";
import { BoxNameArrow } from "../components/box-name-arrow";
import { useSelector, useDispatch } from "react-redux";
import { Footer } from "../components/footer/Footer";
import { actions } from "./redux/ScannerRedux";
import { toasterActions } from "../_redux/ToasterRedux";

export const WorkshopPage = () => {
  const show = useContext(authContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const scanner = useSelector((state) => state.scanner.scanner);
  const selectedEvent = useSelector((state) => state.scanner.selectedEvent);
  const workshops = useSelector((state) => state.scanner.workshops);

  const getWorkshops = useCallback(
    (event, scanner) => {
      const _body = {
        eventCode: event,
        gateCode: scanner,
      };
      dispatch(actions.workshops.search(_body));
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectedEvent && scanner?.data?.code) {
      getWorkshops(selectedEvent.code.trim(), scanner.data.code);
    }
  }, [dispatch, getWorkshops, scanner.data.code, selectedEvent]);

  useEffect(() => {
    if (workshops && workshops.error) {
      dispatch(
        toasterActions.toaster.set({
          message: workshops.error,
          variant: "danger",
          show: true,
        })
      );
    }
  }, [dispatch, workshops, history, selectedEvent]);

  useEffect(() => {
    show.setLogoutCtxt(true);
    show.setBackButtonCtxt(false);
  }, [show]);

  useEffect(() => {
    if (scanner && scanner.data && scanner.data.type !== "workshop")
      history.replace("/events");
  }, [history, scanner]);

  useEffect(() => {
    dispatch(actions.selectedWorkshop.reset());
  }, [dispatch]);

  const goTo = (link) => {
    const timer = setTimeout(() => {
      history.push(link);
      clearTimeout(timer);
    }, 200);
  };

  return (
    <>
      <div className="row mx-0 padding position-absolute top-50 start-50 translate-middle w-100">
        <SectionTitle name="Select Workshop" />
        <div
          className="col-12 px-0 row mx-0 mt-5 justify-content-center"
          style={{
            maxHeight: "400px",
            overflow: "auto",
          }}
        >
          {workshops?.data?.length > 0 &&
            workshops.data.map((workshop, index) => (
              <BoxNameArrow
                marginLeft={false}
                key={index}
                name={workshop.name}
                code={workshop.code}
                onClick={() => {
                  dispatch(actions.selectedWorkshop.set(workshop));
                  setTimeout(() => {
                    history.push("/points");
                  }, 200);
                }}
              />
            ))}
        </div>
      </div>

      {scanner && scanner.data && selectedEvent && (
        <Footer
          path={`${scanner.data.name} / ${selectedEvent.name}`}
          onClick={() => {
            goTo("/events");
          }}
        />
      )}
    </>
  );
};
