import React, { useMemo } from "react";
import { useBreakPoint } from "../../../hook/useBreakPoint";
export const CounterHeader = ({
  result: { icon },
  scanResult: { outcome, code },
}) => {
  const { down } = useBreakPoint();
  const isMobile = useMemo(() => {
    return down("xs");
  }, [down]);

  return (
    <div className="mx-0 px-0">
      <div
        className={`d-flex w-100 flex-column px-3 py-4 justify-content-between accepted`}
      >
        <div className="text-center">
          {icon && (
            <svg width="96" height="96" viewBox="0 0 96 96" fill="#198996">
              <g data-name="Group 35" transform="translate(-64 -128)">
                <g data-name="Group 15" transform="translate(0 -192)">
                  <g
                    data-name="Ellipse 1"
                    transform="translate(64 320)"
                    stroke="#f1f1f1"
                    stroke-width="7"
                  >
                    <circle cx="48" cy="48" r="48" stroke="none" />
                    <circle cx="48" cy="48" r="44.5" fill="none" />
                  </g>
                  <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M-416.15,39.6l11.3,11.693L-376.625,23.2"
                    transform="translate(508.4 330.551)"
                    stroke="#f1f1f1"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                    stroke-width="10.95"
                  />
                </g>
              </g>
            </svg>
          )}

          <p
            className="ps-2 text-light fw-bolder text-center mt-5"
            style={{
              fontSize: isMobile ? "30px" : "60px",
            }}
          >
            {outcome}
          </p>
        </div>

        {code && (
          <div className="text-center mt-5">
            <p
              className="text-center text-white mt-5 w-auto"
              style={{
                fontSize: "29px",
              }}
            >
              {code}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
